.LinksText {
    color: aqua;
    text-align: center;
    font-size: small;
    display: flex;
    padding-top: 0%;
    justify-content: center;
}

.LinksTitle {

}