.TitleText {
    color: aqua;
    text-align: center;
    font-size: xx-large;
    display: flex;
    justify-content: center;
    margin-left: 0%;
    padding-top: 0%;
}

.KnownStuff {
    color: aqua;
    text-align: center;
    font-size: x-large;
    display: flex;
    justify-content: center;
    margin-left: 0%;
}

.ButtonStyle {
    background-color: aqua;
    display: flex;
}

.BlepImage {
    display: block;
    margin-left: 20%;
    margin-right: auto;
    padding-top: 0%;
    width: 60%;
    height:auto;
}

.BlepText {
    text-align: center;
    color: aqua;
    font-size: xxx-large;
    margin-top: -2%;
}

.ButtonPosition {
    display: flex;
    justify-content: center;
}