.Alex_Image {
    display: block;
    margin-left: 44%;
    margin-right: auto;
    padding-top: 1%;
    width: 12%;
    height:auto;
}

.AboutMeText {
    color: aqua;
    text-align: center;
    justify-content: center;
    font-size: xx-large;
    display: flex;
    margin-left: 0%;
    padding-top: 0%;
}
