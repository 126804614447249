.headerTitle {
    color: aqua;
    text-align: center;
    font-size: 7em;

}

.headerSubtitle {
    color: aqua;
    text-align: center;
    font-size: xxx-large;
}

.bottomText {
    color: greenyellow;
    font-size: 8em;
    text-align: center;
}