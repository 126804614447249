body {
  background-image: url("images/big_starry_sky.jpg");
  width: 100%;
}

.App {

}

.font-face-oswald {
  font-family: OswaldRegular;
}

.Column {
  flex: 50%;
}

.Body-Content {
  /*display: flex;*/
}

